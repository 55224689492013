<template>
  <div class="iCVBigPicture">
    <div class="imgDiv" :key="index" v-for="(item,index) in bigImgs">
      <img class="deployImg" alt="" :src="item.image" v-if="imgIndex==item.key">
    </div>
  </div>
</template>

<script>

export default {
  name: 'ICVBigPicture',
  components: {
  },
  data() {
    return {
      imgIndex:'',
      bigImgs:[
        {
          key: "1",
          image: require('@/assets/images/productCenter/iCV/applicationBigImg1.png'),
        },
        {
          key: "2",
          image: require('@/assets/images/productCenter/iCV/applicationBigImg2.png'),
        },
        {
          key: "3",
          image: require('@/assets/images/productCenter/iCV/applicationBigImg3.png'),
        },
        {
          key: "4",
          image: require('@/assets/images/productCenter/iCV/applicationBigImg4.png'),
        }
      ]
    }
  },
  methods: {
  },
  mounted() {
    this.imgIndex = this.$route.query.imgName;
  },
}
</script>

<style lang="less">
@import "@/assets/css/publicStyle.css";
.iCVBigPicture{
  width: 1440px;
  height: 100%;
  margin: 0 auto;
  background: rgba(34, 38, 45, 0.5);
  .imgDiv{
    margin: 30px 0px 50px 0px;
  }
  .deployImg{
    width: 100%;
    height: 100%;
  }
}
</style>