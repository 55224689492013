<template>
  <div class="hmi-tabs-wrapper">
    <SourceTable :page="'HMI'" @preview-video="previewVideo"/>
    <el-dialog class="preview-dialog"
               v-model="dialogVisible"
               :title="dialogTittle"
               width="835"
               draggable
               destroy-on-close
               center>
      <div class="pdf" v-if="previewType === 'pdf'">
        <PreviewPDF :url="previewUrl"/>
      </div>
      <div class="mp4" v-if="previewType === 'mp4'">
        <PreviewMP4 :url="previewUrl"/>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import SourceTable from "@/views/Service/component/SourceTable.vue";
import PreviewPDF from "@/components/preview/PreviewPDF.vue";
import PreviewMP4 from "@/components/preview/PreviewMP4.vue";
import {inject, ref} from "vue";

const previewType = ref("");
const baseUrl = inject('baseUrl');
const previewBaseUrl = ref(baseUrl + "/files/preview/");
const previewUrl = ref("");
const dialogTittle = ref("");
const dialogVisible = ref(false);

const previewVideo = (fileName) => {
  previewUrl.value = previewBaseUrl.value + fileName;
  console.log(previewUrl.value);
  previewType.value = 'mp4';
  dialogVisible.value = true;
  dialogTittle.value = fileName;
};
</script>

<style lang="less">
.hmi-tabs-wrapper{
  height: 100%;
  margin-top: 24px;

  .preview-dialog{
    --el-dialog-bg-color: rgba(250, 250, 252, 0.225);

    .el-dialog__header{
      span{
        color:white;
      }
    }
    .el-dialog__headerbtn .el-dialog__close{
      color: white;
    }
  }
}
</style>
