<template>
  <div class="new-road-wrapper">
    <div class="content">
      <div class="tittle-wrapper">
        <div class="page-tittle">重点新闻</div>
        <div class="tittle">
          {{ tittle }}
          <div class="second-tittle">
            天行系列全栈自主可控PLC技术交流会
          </div>
        </div>
        <div class="date">
          {{ date }}
        </div>
      </div>
      <div class="news">
        <p>
          宝武环科、宝武水务与宝信软件开展工业控制自主化技术交流会，双方围绕宝信软件自主研发的天行系列全栈自主可控PLC技术展开深度探讨，共谋工业自动化领域国产化替代与智能化升级的新篇章。
          宝武环科、宝武水务规划发展部、运营改善部、运营管理中心及各分子公司与宝信软件数工院及自动化服务本部出席交流。
        </p>
        <div class="img third-news-first-img"></div>
        <p class="subTittle">自主可控破局，天行PLC赋能工业智造升级</p>
        <p>
          作为中国工业软件领军企业，宝信软件始终致力于突破工业控制领域“卡脖子”技术难题。此次交流会上，宝信软件重点展示了天行系列PLC的核心优势：该产品从芯片、操作系统到开发环境实现100%全栈自主可控，具备高实时性、
          毫秒级响应速度和多设备协同控制能力、以及高易用性和扩展性，可广泛应用于冶金、环保、能源等复杂工业场景，为关键基础设施的国产化替代提供可靠技术支撑。
        </p>
        <p class="subTittle">高效开发体验，赋能工程创新</p>
        <p>
          现场演示环节中，技术人员展现了天行IDE的高度集成化、开放性、易用性、安全可靠性优势。例如：内置代码自动补全、语法实时纠错功能，搭配高精度虚拟PLC仿真器，可离线模拟设备运行状态，
          DeepSeek接入天行的辅助编程应用，引起与会专家的高度关注。
        </p>
        <div class="img third-news-second-img"></div>
        <p class="subTittle">跨界协同，共筑绿色智造生态</p>
        <p>
          宝武环科、宝武水务作为宝武集团旗下环保产业平台，致力于成为环境治理与绿色材料领域整体解决方案新质供应商。
          交流会上，宝武环科、宝武水务结合环保装备智能化、产线无人化等业务需求，对天行PLC的适配性展开探讨，并提出合作构想。
        </p>
        <p class="subTittle">强强联合，擘画工业未来</p>
        <p>
          此次技术交流不仅是双方技术能力的碰撞，更标志着产业链协同创新突破的开端。天行PLC的规模化应用需要生态伙伴共创共赢，
          与宝武环科、宝武水务的合作将加速技术迭代，推动自主可控PLC从可用向好用易用跨越。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewRoad',
  components: {},
  props:{
    tittle: "",
    date: "",
  },
  methods: {

  },
  onBeforeUnmount() {
    localStorage.removeItem('newsKey')
  },
}

</script>

<style lang="less" scoped>
.new-road-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  .content{
    width: 1090px;
    margin-bottom: 147px;
    .tittle-wrapper{
      width: 1090px;
      height: 320px;
      border-bottom: 2px solid rgb(57, 65, 78);
      margin-bottom: 48px;
      opacity: 1;

      /* 自动布局 */
      display: flex;
      flex-direction: column;
      padding: 0;
      gap: 48px;

      z-index: 0;

      .page-tittle{
        margin-top: 48px;
        width: 1090px;
        height: 26px;
        opacity: 1;

        /* 标题小 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 0;
      }
      .tittle{
        width: 1090px;
        opacity: 1;

        /* 标题大 */
        font-family: Source Han Sans;
        font-size: 28px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0;
        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;
        z-index: 1;

        .second-tittle{
          margin-top: 20px;
          font-size: 20px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0;
          font-variation-settings: "opsz" 0.7;
          /* 最浅 */
          color: #FAFAFC;
        }
      }
      .date{
        width: 1090px;
        height: 23px;
        margin-bottom: 48px;
        opacity: 1;

        /* 正文 */
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 2;
      }
    }
    .news{
      .img{
        width: 409px;
        height: 287px;
        opacity: 1;
      }
      .third-news-first-img{
        background: url('@/assets/images/news/detail/20250321/first.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 294px;
      }
      .third-news-second-img{
        background: url('@/assets/images/news/detail/20250321/second.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 294px;
      }
      p{
        display: block;
        /* 正文 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0em;
        text-indent: 2em;
        line-height:28px;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: rgba(250, 250, 252,0.8);
      }
      .subTittle{
        width: 1090px;
        height: 26px;
        margin-bottom: 24px;
        margin-top: 48px;
        opacity: 1;

        /* 标题小 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;
      }
    }
  }
}
</style>
