<template>
  <div class="preview-mp4-phone-wrapper">
    <vue3VideoPlay
        class="video"
        :src="src"
        @play="onPlay"
        @pause="onPause"
        @timeupdate="onTimeupdate"
        @canplay="onCanplay" />
  </div>
</template>

<script setup>
import {onMounted, toRef} from "vue";

const props = defineProps({
  url: String,
})

const src = toRef(props, 'url')

const onPlay = (ev) => {}
const onPause = (ev) => {}

const onTimeupdate = (ev) => {}
const onCanplay = (ev) => {}
onMounted(() => {})
</script>

<style lang="less">
.preview-mp4-phone-wrapper{
  width: 100%;
  height: 100%;
  .d-player-wrap.d-player-wrap-hover{
    width: 100%;
    height: 100%;
  }
}

</style>
