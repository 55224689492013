<template>
  <div class="wrapper">
    <el-card class="support_card">
      <div class="topDiv">
        <div class="topContentWrapper">
        </div>
      </div>
      <el-card class="download_card">
        <template #header>
          <div class="card-header">
            <span>产品资料</span>
          </div>
        </template>
        <el-tabs class="root-tabs" v-model="activeRootName">
          <el-tab-pane label="PLC" name="PLC">
            <PLCTabPane/>
          </el-tab-pane>
          <el-tab-pane label="DSF" name="DSF">
            <DSFTabPane/>
          </el-tab-pane>
          <el-tab-pane label="iCV" name="iCV">
            <ICVTabPane/>
          </el-tab-pane>
          <el-tab-pane label="HMI" name="HMI">
            <HMITabPane/>
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <el-card class="foot">
        <template #header>
          <div class="card-header">
            <span>联系我们</span>
          </div>
        </template>
        <div class="tel-wrapper">
          <span style="margin-right: 40px">热线电话：400-821-0860</span>
          <span>产品邮箱：baosky@baosight.com</span>
        </div>
      </el-card>
    </el-card>
  </div>
</template>

<script>
import PreviewPDF from "@/components/preview/PreviewPDF.vue";
import PreviewMP4 from "@/components/preview/PreviewMP4.vue";
import SourceTable from "@/views/Service/component/SourceTable.vue";
import PLCTabPane from "@/views/Service/component/PLCTabPane.vue";
import HMITabPane from "@/views/Service/component/HMITabPane.vue";
import {useRoute} from "vue-router";
import DSFTabPane from "@/views/Service/component/DSFTabPane.vue";
import ICVTabPane from "@/views/Service/component/ICVTabPane.vue";
export default {
  name: "ServiceAndSupport",
  components: {
    ICVTabPane,
    DSFTabPane,
    HMITabPane,
    PLCTabPane,
    SourceTable,
    PreviewMP4,
    PreviewPDF
  },
  inject: ['baseUrl'],
  data() {
    return {
      activeRootName: "PLC"
    }
  },
  methods: {

  },
  mounted() {
    //获取页面名称
    let pageName = useRoute().query.pageName;
    if(pageName === undefined){
      this.activeRootName = "PLC";
    }
    if(pageName === "HMI"){
      this.activeRootName = "HMI";
    }
    if(pageName === "DSF"){
      this.activeRootName = "DSF";
    }
    if(pageName === "iCV"){
      this.activeRootName = "iCV";
    }
  },
}
</script>

<style lang="less">
@import "@/assets/css/publicStyle.css";
.wrapper{
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  background: #22262D;

  .support_card{
    width: 1440px;
    padding: 0;
    background: #22262D;
    border:0;
    box-shadow: none !important;
    border-top: 1px solid rgba(35, 44, 58, 0.2);
    --el-card-padding: 0px 64px;
    --el-card-border-radius: 0px;
    .topDiv{
      width: 1359px;
      height: 308px;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      .topContentWrapper{
        width: 1312px;
        height: 267px;
        background: url(@/assets/images/support/top.png) no-repeat center;
      }

    }
    .el-card__body{
      .tittle{
        width: 140px;
        height: 41px;
        margin-left: 609.5px;
        margin-top: 64px;
        margin-bottom: 22px;
        span{
          opacity: 1;

          /* 标题大 */
          font-family: Source Han Sans;
          font-size: 28px;
          font-weight: bold;
          line-height: normal;
          text-align: center;
          letter-spacing: 0;

          font-variation-settings: "opsz" 0.7;
          /* 最浅 */
          color: #FAFAFC;
        }
      }
      .download_card{
        width: 1090px;
        height: 937.5px;
        margin-left: 134.5px;
        background: #22262D;
        margin-top: 49px;
        border:0;
        box-shadow: none !important;
        --el-card-padding: 0px;
        --el-card-border-radius: 0px;
        .el-card__header{
          height: 35px;
          border-bottom:0;
          margin-bottom: 48px;
          .card-header{
            text-align: center;
            span{
              font-family: Source Han Sans;
              font-size: 24px;
              font-weight: bold;
              line-height: 35px;
              color: #FAFAFC;
            }
          }
        }
        .el-card__body{
          width: 100%;
          height: 854.5px;
          margin-top: 48px;

          .root-tabs{
            width: 100%;
            margin: 0 auto;
            --el-tabs-header-height:51.5px;

            .wait-wrapper{
              display: flex;
              justify-content: center;
              align-items: center;
              height: 458px;
              .wait{
                font-family: Source Han Sans;
                font-size: 23px;
                font-weight: bold;
                line-height: 41px;
                color: #FAFAFC;
              }
            }
          }
          .root-tabs > .el-tabs__header{
            margin: 0 0 16px 0;
            .el-tabs__nav-wrap{
              .el-tabs__nav-scroll{
                display: flex;
                flex-direction: row;
                justify-content: center;
                .el-tabs__nav{
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  gap: 48px;
                  .el-tabs__item{
                    width: 142px;
                    font-family: Source Han Sans;
                    font-size: 18px;
                    font-weight: 500;
                    color: #FAFAFC;
                    text-align: center;
                    padding: 0;
                    &:hover {
                      background-color: rgba(1, 174, 227, 0.4)!important;
                      color: #FAFAFC;
                    }
                    &.is-active {
                      border-width: 3px;
                      border-style: solid;
                      border-image: linear-gradient(90deg, #0095EE 0%, #00E09A 100%) 0 0 3 0;
                    }
                  }
                  :nth-last-child(.el-tabs__item){
                    margin-right: 0;
                  }
                }
              }
              &:after {
                background-color: rgba(250,250,252,0.2);
              }
            }
          }
        }
      }

      .foot{
        width: 1090px;
        height: 180px;
        margin-left: 134.5px;
        background: none;
        margin-top: 35px;
        margin-bottom: 54.5px;
        border:0;
        box-shadow: none !important;
        --el-card-padding: 0px;
        --el-card-border-radius: 0px;
        .el-card__header{
          height: 52px;
          border-bottom:0;
          .card-header{
            text-align: center;
            span{
              font-family: Source Han Sans;
              font-size: 24px;
              font-weight: bold;
              line-height: 52px;
              color: #FAFAFC;
            }
          }
        }
        .el-card__body{
          overflow: hidden;
          margin-top: 46px;
          width: 100%;
          height: 85px;
          padding: 0;
          /* 最浅 */
          color: #FAFAFC;
          .tel-wrapper{
            background: rgba(29, 29, 31, 0.2);
            width: 1088px;
            height: 79px;
            line-height: 79px;
            text-align: center;
            border-radius: 10px;
            /* 描边 - 百分 20 浅 */
            border: 1px solid rgba(250, 250, 252, 0.2);
            span{
              height: 79px;
              opacity: 1;
              /* 正文 */
              font-family: Source Han Sans;
              font-size: 16px;
              font-weight: normal;
            }
          }

        }
      }
    }
  }
}
</style>
