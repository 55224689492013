<template>
  <div class="plc-tabs-phone-wrapper">
    <el-tabs class="plc-tabs-phone" v-model="activeName" type="card">
      <el-tab-pane label="T3" name="T3">
        <el-row>
          <SourceTablePhone :page="'t3'" @preview-video="previewVideo"/>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="T4" name="T4">
        <el-row>
          <SourceTablePhone :page="'t4'" @preview-video="previewVideo"/>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="IDE" name="IDE">
        <el-row>
          <SourceTablePhone :page="'IDE'" @preview-video="previewVideo"/>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <el-dialog class="preview-dialog"
               v-model="dialogVisible"
               :title="dialogTittle"
               width="80%"
               draggable
               destroy-on-close
               center>
      <div class="pdf" v-if="previewType === 'pdf'">
        <PreviewPDFPhone :url="previewUrl"/>
      </div>
      <div class="mp4" v-if="previewType === 'mp4'">
        <PreviewMP4Phone :url="previewUrl"/>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {inject, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import SourceTablePhone from "@/views/Service/component_phone/SourceTablePhone.vue";
import PreviewMP4Phone from "@/components/preview/PreviewMP4Phone.vue";
import PreviewPDFPhone from "@/components/preview/PreviewPDFPhone.vue";

const activeName = ref('T3');
const previewType = ref("");
const baseUrl = inject('baseUrl');
const previewBaseUrl = ref(baseUrl + "/files/preview/");
const previewUrl = ref("");
const dialogTittle = ref("");
const dialogVisible = ref(false);

const previewVideo = (fileName) => {
  previewUrl.value = previewBaseUrl.value + fileName;
  console.log(previewUrl.value);
  previewType.value = 'mp4';
  dialogVisible.value = true;
  dialogTittle.value = fileName;
};

onMounted(() => {
  //获取页面名称
  let pageName = useRoute().query.pageName;
  if(pageName === undefined){
    activeName.value = "T3";
  }
  if(pageName === "T3"){
    activeName.value = "T3";
  }
  else if(pageName === "T4"){
    activeName.value = "T4";
  }
  else if(pageName === "IDE"){
    activeName.value = "IDE";
  }
})
</script>

<style lang="less">
@import "@/assets/css/publicStyle.css";

.plc-tabs-phone-wrapper{
  .plc-tabs-phone{
    height: 100%;
    margin-top: 24px;
    .el-tabs__header{
      height: 40px;
      border: none;
      .el-tabs__nav-wrap{
        height: 100%;
        .el-tabs__nav-scroll{
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          .el-tabs__nav{
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 10px;
            border:0;
            .el-tabs__item{
              width: 80px;
              height: 30px;
              opacity: 1;

              /* 自动布局 */
              display: flex;
              flex-direction: column;
              padding: 6px 48px;
              background: #232C3A;

              box-sizing: border-box;
              border: 1px solid rgb(0, 220, 158);
              /* 主渐变色 */
              //border-image: linear-gradient(90deg, #0095EE 0%, #00E09A 100%) 1;
              //clip-path: inset(0 round 4px);
              border-radius: 36px;

              font-family: Source Han Sans;
              font-size: 12px;
              font-weight: 500;
              line-height: 42px;
              color: #FAFAFC;
            }
            .is-active{
              background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
              /* 描边 - 百分 20 浅 */
              border: 1px solid rgba(250, 250, 252, 0.2);
              border-radius: 36px;
            }
          }
        }
      }
    }
    .el-tabs__content{
      .el-tab-pane{
        width: 100%;
        height: auto;
        margin-top: 12px;
        .el-row{
          height: 100%;
        }
      }
    }
  }
  .preview-dialog{
    --el-dialog-bg-color: rgba(250, 250, 252, 0.225);

    .el-dialog__header{
      span{
        color:white;
      }
    }
    .el-dialog__headerbtn .el-dialog__close{
      color: white;
    }

    .mp4,.pdf{
      width: 100%;
    }
  }
}
</style>
