<template>
  <div class="wuhangWrapper">
    <div class="content">
      <div class="tittle-wrapper">
        <div class="page-tittle">公司新闻</div>
        <div class="tittle">
          {{tittle}}
        </div>
        <div class="date">
          {{ date + "·武汉宝信" }}
        </div>
      </div>
      <div class="news">
        <el-row>
          <p>
            在宝信软件自动化事业本部的指导下，由武汉宝信协同组织实施的新余钢铁硅钢薄板事业部（轧钢区）超低排放改造项目于 1 月 8 日热负荷试车投产，
            标志着该项目首次应用宝信软件自主研发天行 PLC 的 T3 系列取得成功。
          </p>
          <p>
            在该项目中，宝信天行 PLC 作为控制核心,不仅支持 Profinet 协议，完美适配了现场 ABB 高压变频器，控制除尘风机运转，同时通过 TCP 协议
            与微雾抑尘系统、主线 L2 系统进行通讯,整个系统运行良好最大效率满足了新钢 1580 热轧产线超低排放要求。该项目成功应用不仅提升了系统的自
            动化水平，除尘效率也显著提高，为新余钢铁的环保目标提供了有力支持。
          </p>
          <div class="img-wrapper"/>
          <p>
            在项目实施过程中，武汉宝信自动化事业部项目团队与数字工业研究院团队齐心协力，从系统设计、设备制造、安装调试、人员培训等各方面密切合作，
            有力的推动了项目顺利实施。在项目调试阶段，面对本项目接口多、网络复杂、控制点分散等不利因素，项目团队及时响应，解决了现场调试过程中的各种问题，
            确保系统按时上线，得到了用户的信任与肯定。
          </p>
          <p>
            新余钢铁硅钢薄板事业部超低排放改造项目的成功上线，是武汉宝信自动化事业部首次在项目中成功应用宝信软件天行 PLC 产品,也是在践行国产化自主
            可控道路上迈出的重要一步。下一步，在宝信软件自动化事业本部大力支持下，武汉宝信自动化事业部将继续加强与数字工业研究院的协同合作，
            为宝信软件天行PLC 产品的市场开拓贡献力量。
          </p>
        </el-row>
        <div class="footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WuHanBaosightPhone',
  props:{
    tittle: "",
    date: "",
  },
  components: {},
  methods: {

  },
  onBeforeUnmount() {
    localStorage.removeItem('newsKey')
  },
}

</script>

<style lang="less" scoped>
.wuhangWrapper{
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: none;
  .content{
    padding: 0px 12px;
    .tittle-wrapper{
      width: 100%;
      height: auto;
      border-bottom: 2px solid rgb(57, 65, 78);
      margin-bottom: 48px;
      opacity: 1;

      /* 自动布局 */
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 48px;

      z-index: 0;

      .page-tittle{
        margin-top: 48px;
        width: 100%;
        height: 26px;
        opacity: 1;

        /* 标题小 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 0;
      }
      .tittle{
        width: 100%;
        height: auto;
        opacity: 1;

        /* 标题大 */
        font-family: Source Han Sans;
        font-size: 28px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 1;
      }
      .date{
        width: 100%;
        height: 23px;
        margin-bottom: 48px;
        opacity: 1;

        /* 正文 */
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 2;
      }
    }
    .news{
      .img-wrapper{
        width: 140px;
        height: 299px;
        background: url('@/assets/images/news/detail/20250117/wuhan.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 30%;
      }
      p{
        display: block;
        /* 正文 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0em;
        text-indent: 2em;
        line-height:28px;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: rgba(250, 250, 252,0.8);
      }
    }
  }
  .footer{
    height: 200px;
    width: 100%;
  }
}
</style>
