<template>
  <div class="source-table-phone-wrapper">
    <el-table :data="list"  class="table" height="608.5">
      <el-table-column prop="fileName" label="文件名" align="left" show-overflow-tooltip width="200"/>
      <el-table-column prop="type" label="资料分类" align="center" width="100"/>
      <el-table-column prop="version" label="版本号" align="center"/>
      <el-table-column prop="size" label="文件大小" align="center"/>
      <el-table-column align="center" label="操作" width="212.5">
        <template v-slot="scope">
          <el-button style="margin-right: 10px"
                     class="preview"
                     v-if="canPreview(scope.row) && scope.row.suffix==='.pdf'"
                     @click="previewNewWindow(scope.row)">预览</el-button>
          <el-button class="manual" v-if="scope.row.suffix === 'manual'" @click="gotoManual">跳转</el-button>
          <el-button style="margin-right: 10px"
                     class="preview"
                     v-if="canPreview(scope.row) && scope.row.suffix==='.mp4'"
                     @click="preview(scope.row.fileName)">预览</el-button>
          <el-button class="download" @click="downloadFile(scope.row)" v-if="scope.row.suffix !== 'manual'">下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-phone">
      <el-pagination background
                     :page-size="pageSize"
                     :current-page = "currentPage"
                     layout="pager"
                     :total="total"
                     @current-change="handleCurrentChange"/>
    </div>
  </div>
</template>

<script setup>
import {inject, onMounted, ref} from "vue";
import {getAll} from "@/api/file";
import {ElMessage} from "element-plus";
import {reactive} from "vue-demi";

const props = defineProps({
  page: String
});

const emit = defineEmits(['previewVideo']);

const list = ref([]);
const pageSize = ref(12);
const currentPage = ref(1);
const total = ref(0);
const queryParams = reactive({
  page: props.page,
  pageNum: 1,
  pageSize: 12,
});
const baseUrl = inject('baseUrl');
const previewBaseUrl = baseUrl + "/files/preview/";
const downloadUrl = baseUrl + "/files/download/"
const getExtension = (name) => {
  return name.substring(name.lastIndexOf("."))
};
const fetchData = () => {
  getAll(queryParams).then((response) => {
    if(response === undefined) return
    if(response.data.code === 0){
      list.value = response.data.rows;
      list.value.map((item) => {
        item.suffix = getExtension(item.fileName);
        if(item.suffix.charAt(0) !== '.'){
          item.suffix = "manual";
        }
      });
      total.value = response.data.total;
    }
  }).catch(() => {
    ElMessage.error("加载数据失败");
  })
};

const canPreview = (item) => {
  return item.suffix === '.pdf' || item.suffix === '.mp4'
};

const canOpenPdf = ref(false);
//基础 UA 检测（快速过滤）
const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  console.log(ua)
  return {
    isIOS: /iphone|ipad|ipod/i.test(ua),
    isAndroid: /android/i.test(ua),
    isEdge: /edg|edge/i.test(ua),
    isFirefox: /firefox|fxios/i.test(ua),
    isWeChat: /micromessenger/i.test(ua),
    isHarmonyOS: /harmonyos|hmos/i.test(ua)
  };
};
//WebView 环境检测
const isInAppWebView = () => {
  const ua = navigator.userAgent;
  // 微信/QQ/TikTok 等常见 App 检测
  return /micromessenger|qq|tiktok|weibo/i.test(ua) &&
      !/browser|mqqbrowser|safari/i.test(ua);
};
async function canPreviewPdf() {
  const { isIOS, isAndroid, isEdge, isHarmonyOS, isWeChat } = getBrowserInfo();

  // 明确不支持的环境
  if (isWeChat || isInAppWebView()) return false;

  // iOS 默认支持
  if (isIOS) return true;

  // Android Chrome 90+
  return (isAndroid || isHarmonyOS) && isEdge;
}

const previewNewWindow = (item) => {
  if(canOpenPdf.value){
    window.open(previewBaseUrl + item.fileName);
  }else {
    downloadFile(item);
  }
};
const preview = (fileName) => {
  emit('previewVideo', fileName);
};
const gotoManual = () => {
  window.open("https://baosky.baosight.com/usermanual/")
};
const downloadFile = (item) => {
  let link = document.createElement('a');
  link.href = downloadUrl + item.fileName
  link.download = item.fileName//下载后文件名
  document.body.appendChild(link);
  link.click();//点击下载
  link.remove();//下载完成移除元素
  window.URL.revokeObjectURL(link.href); //用完之后使用URL.revokeObjectURL()释放
};
const handleCurrentChange = (val) => {
  currentPage.value = val
  queryParams.pageNum = val
  fetchData();
};

onMounted(async () => {
  fetchData();
  canOpenPdf.value = await canPreviewPdf();
  console.log(canOpenPdf.value);
});
</script>

<style lang="less">
.source-table-phone-wrapper{
  width: 100%;
  .el-table{
    width: 100%;
    --el-table-border-color: none;
    --el-table-row-hover-bg-color: rgba(165, 165, 182, 0.5);
    --el-table-border: none;
    background: none;
    .el-table__header-wrapper{
      width: 100%;
      .el-table__header{
        thead {
          background: none;
          font-family: Noto Sans SC;
          font-size: 14px;
          font-weight: bold;
          line-height: normal;
          text-align: center;
          letter-spacing: 0;

          font-variation-settings: "opsz" 0.7;
          /* 最浅 */
          color: #FAFAFC;
          tr{
            background: none;
            th{
              background: none;
            }
          }
        }
      }
    }
    .el-table__body-wrapper{
      tr{
        background: none;
        height: 20px;
        opacity: 1;

        /* 补充文字大 */
        font-family: Source Han Sans;
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        .el-button{
          width: 60px;
          background: none;
          border: none;
          /* 正文 */
          font-family: Source Han Sans;
          font-size: 12px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0;
          margin-left: 0;
          padding: 0;

          font-variation-settings: "opsz" 0.7;
          /* 主色蓝-苍穹蓝 */
          color: #0095EE;
        }
        .el-button:hover{
          background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
        }
        .el-button:before{
          content: '';
          display: inline-block;
          height: 14px;
          margin-right: 4px;
        }
        .preview:before{
          width: 17.64px;
          background: url("@/assets/images/support/button/preview.png") no-repeat;
          background-size: 100% 100%;
        }
        .download:before{
          width: 15px;
          background: url("@/assets/images/support/button/download.png") no-repeat;
          background-size: 100% 100%;
        }
        .manual:before{
          height: 14px;
          width: 16px;
          background: url("@/assets/images/support/button/go.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .page-phone{
    height: 72px;
    width: 100%;
    display: flex;
    justify-content: center;

    .el-pagination{
      height: 32px;
      margin-top: 40px;
      ul{
        li{
          width: 32px;
          height: 32px;
          border-radius: 2px;
          box-sizing: border-box;
          /* 描边 - 百分 20 浅 */
          border: 1px solid rgba(250, 250, 252, 0.2);
          opacity: 1;

          /* 自动布局 */
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 8px 18px;
          margin-left: 16px;
          background: none;
          /* 补充文字中 */
          font-family: Source Han Sans;
          font-size: 12px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0em;

          font-variation-settings: "opsz" 0.7;
          /* 最浅 */
          color: #FAFAFC;
        }
        :first-child {
          margin-left: 0;
        }
        .is-active{
          background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
        }
      }

    }
  }
}
</style>
