<template>
  <div class="webContent">
    <!-- 轮播图 -->
    <el-card class="slideshow_card">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item key="1">
          <img class="bgImage" alt="" src="@/assets/images/home/slideshowBg.png" @click="gotoProductCenterView">
        </el-carousel-item>
      </el-carousel>
    </el-card>
    <!-- 国内唯一全栈自主可控制工业操作系统 -->
    <el-card class="controllable_card">
      <template #header>
        <div class="card-header">
          <span>国内唯一全栈自主可控制工业操作系统</span>
        </div>
      </template>
      <el-row class="contentRow">
        <el-col class="col1" :span="7">
          <span class="textSpan1">1个</span>
          <span class="textSpan2">⼯业现场数据底座</span>
        </el-col>
        <el-col class="col2" :span="7">
          <span class="textSpan1">1个</span>
          <span class="textSpan2">开放式组态平台</span>
        </el-col>
        <el-col class="col3" :span="10">
          <span class="textSpan1">4个</span>
          <span class="textSpan2">核⼼产品+</span>
          <span class="textSpan3">N个</span>
          <span class="textSpan2">扩展产品</span>
        </el-col>
      </el-row>
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item key="1">
          <img class="bgImage" alt="" src="@/assets/images/home/controllableBg1.png" @click="gotoProductCenterView">
        </el-carousel-item>
        <el-carousel-item key="1">
          <img class="bgImage" alt="" src="@/assets/images/home/controllableBg2.png" @click="gotoProductCenterView">
        </el-carousel-item>
      </el-carousel>
    </el-card>
    <!-- 自主可控的意义-->
    <el-card class="steerable_card">
      <template #header>
        <div class="card-header">
          <span>自主可控的意义</span>
        </div>
      </template>
      <!-- <el-carousel type="card" :autoplay="false">
        <el-carousel-item class="carouselItem1" key="1">
          <div class="contentDiv">
            <div class="titleDiv">
              <span class="titleSpan">提升产业安全性</span>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carouselItem2" key="2">
          <div class="contentDiv">
            <div class="titleDiv">
              <span class="titleSpan">推动技术创新<br/>产业升级</span>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carouselItem3" key="3">
          <div class="contentDiv">
            <div class="titleDiv">
              <span class="titleSpan">培育国内产业生态</span>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="carouselItem4" key="4">
          <div class="contentDiv">
            <div class="titleDiv">
              <span class="titleSpan">万亿级市场规模</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel> -->
      <el-row class="contentRow">
        <el-col :class='className1' :span="6" @mouseover="col1MouseOver" @mouseleave="allMouseleave">
          <div class="contentDiv">
            <div class="titleDiv">
              <span class="titleSpan">提升产业安全性</span>
            </div>
          </div>
        </el-col>
        <el-col :class='className2' :span="6" @mouseover="col2MouseOver" @mouseleave="allMouseleave">
          <div class="contentDiv">
            <div class="titleDiv">
              <span class="titleSpan">推动技术创新<br/>产业升级</span>
            </div>
          </div>
        </el-col>
        <el-col :class='className3' :span="6" @mouseover="col3MouseOver" @mouseleave="allMouseleave">
          <div class="contentDiv">
            <div class="titleDiv">
              <span class="titleSpan">培育国内产业生态</span>
            </div>
          </div>
        </el-col>
        <el-col :class='className4' :span="6" @mouseover="col4MouseOver" @mouseleave="allMouseleave">
          <div class="contentDiv">
            <div class="titleDiv">
              <span class="titleSpan">万亿级市场规模</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 应用案例 -->
    <el-card class="case_card">
      <template #header>
        <div class="card-header">
          <span>应用案例</span>
        </div>
      </template>
      <el-row class="contentRow">
        <el-col class="col1" :span="8">
          <div class="contentDiv">
            <div class="iconDiv">
              <img class="iconImg" alt="" src="@/assets/images/home/TiscoIcon.png">
            </div>
            <div class="textDiv1">
              <span class="textSpan">太钢引带机组</span>
            </div>
            <div class="textDiv2">
              <span class="textSpan">天行PLC在冷轧的首次应用<br/>
                提供设计-集成-供货-调试一体化解决方案</span>
            </div>
            <div class="titleDiv">
              <span class="titleSpan">太原钢铁</span>
            </div>
          </div>
        </el-col>
        <el-col class="col2" :span="8">
          <div class="contentDiv">
            <div class="iconDiv">
              <img class="iconImg" alt="" src="@/assets/images/home/BaosteelIcon.png">
            </div>
            <div class="textDiv1">
              <span class="textSpan">宝钢股份平整机组电气改造</span>
            </div>
            <div class="textDiv2">
              <span class="textSpan">天行PLC在冷轧年修项目的首次应用<br/>
                提供设计-集成-供货-调试一体化解决方案</span>
            </div>
            <div class="titleDiv">
              <span class="titleSpan">上海宝钢</span>
            </div>
          </div>
        </el-col>
        <el-col class="col3" :span="8">
          <div class="contentDiv">
            <div class="iconDiv">
              <img class="iconImg" alt="" src="@/assets/images/home/HwhgIcon.png">
            </div>
            <div class="textDiv1">
              <span class="textSpan">广西宏旺冷轧工程</span>
            </div>
            <div class="textDiv2">
              <span class="textSpan">天行PLC在冷轧厂首次大规模应用<br/>
                提供设计-集成-供货-调试一体化解决方案</span>
            </div>
            <div class="titleDiv">
              <span class="titleSpan">广西宏旺</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="imageRow">
        <el-col :span="24">
          <img class="bgImage" alt="" src="@/assets/images/home/caseBg.png">
        </el-col>
      </el-row>
    </el-card>
    <!-- 我们的优势 -->
    <el-card class="advantage_card">
      <template #header>
        <div class="card-header">
          <span>我们的优势</span>
        </div>
      </template>
      <div class="contentRow">
        <div class="col1">
          <div class="contentDiv">
            <div class="iconDiv">
              <img class="iconImg" alt="" src="@/assets/images/home/supportIcon.png">
            </div>
            <div class="titleDiv">
              <span class="titleSpan">宝武的支持</span>
            </div>
            <div class="textDiv">
              <span class="textSpan">依托产业规模，为应用试行提供了庞大的业务资源， 凭借深厚的行业积淀，构建起了一系列严谨且全面的验证场景</span>
            </div>
          </div>
        </div>
        <div class="col2">
          <div class="contentDiv">
            <div class="iconDiv">
              <img class="iconImg" alt="" src="@/assets/images/home/homeIcon.png">
            </div>
            <div class="titleDiv">
              <span class="titleSpan">强大的工程服务</span>
            </div>
            <div class="textDiv">
              <span class="textSpan">由一群经验丰富、技能精湛的技术专家组成的专业的现场服务支撑团队，凭借扎实的专业知识和娴熟的操作技巧迅速应对，为项目的顺利推进提供人力保障。</span>
            </div>
          </div>
        </div>
        <div class="col3">
          <div class="contentDiv">
            <div class="iconDiv">
              <img class="iconImg" alt="" src="@/assets/images/home/pencilIcon.png">
            </div>
            <div class="titleDiv">
              <span class="titleSpan">高端的定制化服务</span>
            </div>
            <div class="textDiv">
              <span class="textSpan">专业的研发团队运用先进的技术架构与灵活的模块设计理念，
                不仅满足当下的功能诉求，
                更赋予强大的可扩展性，
                使其随着用户业务的发展而不断进化。
              </span>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
//公共图标按钮
import { Right } from '@element-plus/icons-vue'

export default {
  name: 'WebContent',
  components: {
    Right
  },
  data() {
    return {
      activeNames: '1',
      className1:'col1',
      className2:'col4',
      className3:'col1',
      className4:'col1',
    }
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    //跳转到"产品中心"页面
    gotoProductCenterView: function (e) {
      //跳转页面
      this.$router.push({
        name:'productCenterView',
      })
    },
    col1MouseOver(){
      this.className1="col3";
      this.className2="col2";
      this.className3="col1";
      this.className4="col1";
    },
    col2MouseOver(){
      this.className1="col1";
      this.className2="col4";
      this.className3="col1";
      this.className4="col1";
    },
    col3MouseOver(){
      this.className1="col1";
      this.className2="col2";
      this.className3="col3";
      this.className4="col1";
    },
    col4MouseOver(){
      this.className1="col1";
      this.className2="col2";
      this.className3="col1";
      this.className4="col3";
    },
    allMouseleave(){
      this.className1="col1";
      this.className2="col4";
      this.className3="col1";
      this.className4="col1";
    },
  },
  mounted() {
  },
}
</script>

<style lang="less">
@import "@/assets/css/icon/style.css";
@import "@/assets/css/publicStyle.css";
.webContent{
  // 轮播图卡片
  .slideshow_card{
    width: 1440px;
    height: 591px;
    margin: 0 auto;
    background: none;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px;
    --el-card-border-radius: 0px;
    .el-card__body{
      // 走马灯
      .el-carousel{
        height: 553px;
        .el-carousel__container{
          .el-carousel__item{
            height: 553px;
            .bgImage{
              width: 100%;
              height: 553px;
              //z-index: -1;
              position: fixed;
              top: 0;
              left: 0;
              &:hover{
                cursor: pointer;
                transform: scale(1.1);
              }
            }
          }
        }
        .el-carousel__indicators{
          bottom: 30px;
        }
      }
    }
  }
  // "国内唯⼀全栈⾃主可控⼯业操作系统"卡片
  .controllable_card{
    width: 1440px;
    height: 970px;
    margin: 0 auto;
    background: none;
    margin-top: 80px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px 25px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 52px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 36px;
          font-weight: bold;
          line-height: 52px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      height: 886px;
      margin-top: 32px;
      padding:0px;
      .contentRow{
        width: 100%;
        height: 48px;
        .col1{
          text-align: right;
        }
        .col2{
          text-align: center;
        }
        .col3{
          text-align: left;
        }
        .textSpan1{
          font-family: Source Han Sans;
          font-size: 24px;
          font-weight: bold;
          line-height: 48px;
          color: #0095EE;
        }
        .textSpan2{
          font-family: Source Han Sans;
          font-size: 24px;
          font-weight: bold;
          line-height: 48px;
          color: #FAFAFC;
        }
        .textSpan3{
          font-family: Source Han Sans;
          font-size: 24px;
          font-weight: bold;
          line-height: 48px;
          background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      // 走马灯
      .el-carousel{
        height: 810px;
        margin-top: 28px;
        .el-carousel__container{
          .el-carousel__item{
            height: 810px;
            .bgImage{
              width: 100%;
              height: 810px;
              position: fixed;
              top: 0;
              left: 0;
              &:hover{
                cursor: pointer;
                transform: scale(1.1);
              }
            }
          }
        }
        .el-carousel__indicators{
          bottom: 10px;
        }
      }
      // 折叠面板
      .el-collapse{
        --el-collapse-border-color: rgba(250, 250, 252, 0.2);
        --el-collapse-header-text-color:#FAFAFC;
        --el-collapse-header-font-size: 18px;
        border:0px;
        .el-collapse-item{
          // 折叠框展开后
          &.is-active {
            .el-collapse-item__header{
              background: rgba(29, 29, 31, 0.2);
              border-width: 2px;
              border-style: solid;
              border-image: linear-gradient(90deg, #0095EE 0%, #00E09A 100%) 0 0 2 0;
              // 箭头图片
              .collapse-icon{
                display: none;
              }
              .bottom_arrow_img{
                display: block;
              }
              // 标题
              .collapse-title{
                font-family: Source Han Sans;
                font-size: 18px;
                font-weight: 500;
                background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              }
            }
          }
          .el-collapse-item__header{
            height: 48px;
            background: #22262D;
            text-align: left;
            // 箭头图片
            .collapse-icon{
              height: 1.5em;
              width: 1.5em;
              line-height: 1.5em;
              svg{
                height: 1.5em;
                width: 1.5em;
              }
            }
            .bottom_arrow_img{
              display: none;
            }
            // 标题
            .collapse-title{
              font-family: Source Han Sans;
              font-size: 18px;
              font-weight: 500;
              color: #FAFAFC;
              float: left;
              margin-left: 10px;
            }
            // 原折叠面板的图标
            .el-collapse-item__arrow{
              display: none;
            }
          }
          .el-collapse-item__wrap{
            background: rgba(29, 29, 31, 0.2);
            .el-collapse-item__content{
              padding-bottom: 10px;
              .contentDiv{
                margin-top: 16px;
                .textDiv{
                  width: 100%;
                  text-align: left;
                  .textSpan{
                    font-family: Source Han Sans;
                    font-size: 16px;
                    line-height: 20px;
                    color: rgba(250, 250, 252, 0.8);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // "自主可控的意义"卡片
  .steerable_card{
    width: 1440px;
    height: 724px;
    margin: 0 auto;
    background: ~"url(@/assets/images/home/steerableBg.png)";
    background-size: 100% 100%;
    margin-top: 80px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 52px;
      border-bottom:0px;
      margin-top: 120px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 36px;
          font-weight: bold;
          line-height: 52px;
          color: #1D1D1F;
        }
      }
    }
    .el-card__body{
      .el-carousel--card{
        height: 260px;
        margin: 60px 64px;
        background: rgba(255, 255, 255, 0.01);
        border: 1px solid rgba(35, 44, 58, 0.2);
        .el-carousel__container{
          .el-carousel__item{
            width: 20%;
            &.is-active {
              background: linear-gradient(46deg, #0095EE 0%, #00E09A 100%);
              box-sizing: border-box;
              /* 描边 - 百分 20 底色 */
              border: 1px solid rgba(35, 44, 58, 0.2);
              box-shadow: 0px 14px 38px 0px rgba(0, 81, 129, 0.3);
              .contentDiv{
                .titleDiv{
                  .titleSpan{
                    font-size: 28px;
                    color: #FAFAFC;
                  }
                }
              }
            }
            .carouselItem1{

            }
            .contentDiv{
              height: 260px;
              .titleDiv{
                width: 100%;
                height: 35px;
                text-align: center;
                padding-top: 36px;
                .titleSpan{
                  font-family: Source Han Sans;
                  font-size: 24px;
                  font-weight: bold;
                  line-height: 35px;
                  color: #333336;
                }
              }
            }
          }
        }
      }
      .contentRow{
        height: 260px;
        margin: 60px 64px;
        background: rgba(255, 255, 255, 0.01);
        border: 1px solid rgba(35, 44, 58, 0.2);
        .col1{
          height: 260px;
          .titleDiv{
            width: 100%;
            height: 260px;
            text-align: center;
            .titleSpan{
              font-family: Source Han Sans;
              font-size: 24px;
              font-weight: bold;
              line-height: 260px;
              color: #333336;
            }
          }
        }
        .col2{
          height: 260px;
          .titleDiv{
            width: 100%;
            height: 35px;
            text-align: center;
            padding-top: 113px;
            .titleSpan{
              font-family: Source Han Sans;
              font-size: 24px;
              font-weight: bold;
              line-height: 35px;
              color: #333336;
            }
          }
        }
        .col3{
          height: 300px;
          background: linear-gradient(46deg, #0095EE 0%, #00E09A 100%);
          box-sizing: border-box;
          /* 描边 - 百分 20 底色 */
          border: 1px solid rgba(35, 44, 58, 0.2);
          box-shadow: 0px 14px 38px 0px rgba(0, 81, 129, 0.3);
          margin-top: -20px;
          .titleDiv{
            width: 100%;
            height: 300px;
            text-align: center;
            .titleSpan{
              font-family: Source Han Sans;
              font-size: 28px;
              font-weight: bold;
              line-height: 300px;
              color: #FAFAFC;
            }
          }
        }
        .col4{
          height: 300px;
          background: linear-gradient(46deg, #0095EE 0%, #00E09A 100%);
          box-sizing: border-box;
          /* 描边 - 百分 20 底色 */
          border: 1px solid rgba(35, 44, 58, 0.2);
          box-shadow: 0px 14px 38px 0px rgba(0, 81, 129, 0.3);
          margin-top: -20px;
          .titleDiv{
            width: 100%;
            height: 35px;
            text-align: center;
            padding-top: 109px;
            .titleSpan{
              font-family: Source Han Sans;
              font-size: 28px;
              font-weight: bold;
              line-height: 35px;
              color: #FAFAFC;
            }
          }
        }
      }
    }
  }
  // "应用案例"卡片
  .case_card{
    width: 1440px;
    height: 710px;
    margin: 0 auto;
    background: none;
    margin-top: 80px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 52px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 36px;
          font-weight: bold;
          line-height: 52px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      height: 631px;
      margin-top: 32px;
      .contentRow{
        width: 100%;
        height: 271px;
        margin-left: 0px !important;
        margin-right: 0px !important;
        border-top: 0.5px solid #AEB4BD;
        .col1,.col2{
          border-right: 0.5px solid #AEB4BD;
        }
        .contentDiv{
          height: 261px;
          margin-left: 119px;
          margin-right: 60px;
          .iconDiv{
            height: 52px;
            margin-top: 32px;
            .supportIcon{
              width: 50%;
              height: 52px;
            }
          }
          .titleDiv{
            width: 100%;
            height: 23px;
            text-align: left;
            padding-top: 38px;
            .titleSpan{
              font-family: Source Han Sans;
              font-size: 16px;
              font-weight: bold;
              line-height: 23px;
              color: rgba(250, 250, 252, 0.6);
            }
          }
          .textDiv1{
            width: 100%;
            height: 20px;
            text-align: left;
            margin-top: 28px;
            .textSpan{
              font-family: Source Han Sans;
              font-size: 16px;
              font-weight: bold;
              line-height: 20px;
              color: #FAFAFC;
            }
          }
          .textDiv2{
            width: 100%;
            height: 46px;
            text-align: left;
            margin-top: 20px;
            .textSpan{
              font-family: Source Han Sans;
              font-size: 16px;
              line-height: 20px;
              color: #FAFAFC;
            }
          }
        }
      }
      .imageRow{
        width: 100%;
        height: 360px;
        background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
        .el-col-24{
          width: 100%;
          height: 360px;
        }
        .bgImage{
          padding: 90px 174px;
        }
      }
    }
  }
  // "我们的优势"卡片
  .advantage_card{
    width: 1440px;
    height: 330px;
    margin: 0 auto;
    background: none;
    margin-top: 80px;
    margin-bottom: 155px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 52px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 36px;
          font-weight: bold;
          line-height: 52px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      margin-top: 60px;
      padding: 0px;
      .contentRow{
        width: 100%;
        height: 218px;
        //padding: 0px 100px;
        // margin-left: 0px !important;
        // margin-right: 0px !important;
        .col1,.col2,.col3{
          width: 372px;
          height: 208px;
          float: left;
        }
        .col1,.col2{
          margin-right: 162px;
        }
        .contentDiv{
          height: 208px;
          .iconDiv{
            height: 52px;
            padding-left: 45%;
            .supportIcon{
              width: 50%;
              height: 52px;
            }
          }
          .titleDiv{
            width: 100%;
            height: 35px;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 24px;
            border-bottom: 0.5px solid rgba(250, 250, 252, 0.2);
            .titleSpan{
              font-family: Source Han Sans;
              font-size: 28px;
              font-weight: bold;
              line-height: 35px;
              background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
          .textDiv{
            width: 100%;
            height: 46px;
            text-align: left;
            padding-top: 20px;
            .textSpan{
              font-family: Source Han Sans;
              font-size: 16px;
              line-height: 20px;
              color: #FAFAFC;
            }
          }
        }
      }
    }
  }
}
</style>

