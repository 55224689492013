const newsData = [
  {
    key: "2025032100",
    tittle: "宝武环科、宝武水务与宝信软件共探工业控制自主化新路径",
    date: "2025年3月21日",
    image: require('@/assets/images/news/detail/20250321/first.png'),
    component: "NewRoad"
  },
  {
    key: "2025012200",
    tittle: "武汉宝信助力宝信软件自主研发 天行PLC产品在新余钢铁成功应用",
    date: "2025年1月22日",
    image: require('@/assets/images/news/detail/20250117/index.png'),
    component: "WuHanBaosight"
  },
  {
    key: "2024112601",
    tittle: "宝信软件闪耀2024国家工业软件大会，iPlat-BA荣获创新大奖",
    date: "2024年11月26日",
    image: require('@/assets/images/news/detail/4.png'),
    component: "IPlat"
  },
  {
    key: "2024112600",
    tittle: "固本强基|曾俊伟自动化工作室开展宝信天行PLC技术交流会",
    date: "2024年11月26日",
    image: require('@/assets/images/news/detail/3-1.png'),
    component: "Meeting"
  },
  {
    key: "2024092000",
    tittle: "天行启势 铸大国重器——宝信软件全栈自主可控PLC产品隆重首发",
    date: "2024年9月20日",
    image: require('@/assets/images/news/first.png'),
    component: "Debut"
  },
]

export function getNewsByKey(key){
  for (let i = 0; i < newsData.length; i++) {
    if(newsData[i].key === key){
        return newsData[i]
    }
  }
}
export function getAll(){
  return newsData
}
